<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="#ba1465" dark v-bind="attrs" v-on="on">
          Add Vehicle</v-btn
        >
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Vehicle Profile</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-select
                  v-model="vehicleType"
                  filled
                  :items="['Bike', 'Car', 'Van', 'Truck']"
                  label="Select vehicleType*"
                  required
                ></v-select>
                <v-text-field
                  v-model="regNo"
                  filled
                  label="Reg No*"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn dark color="#ba1465" @click="AddAssetFuc()"> Proceed </v-btn>
        </v-card-actions>
        <div class="text-center"></div>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  components: {},
  data: () => ({
    dialog: false,
    vehicleType: "",
    regNo: "",
  }),
  methods: {
    AddAssetFuc() {
      if (this.vehicleType == "" || this.regNo == "") {
        this.$store.commit("setSnackbar", true);
        this.$store.commit("setServerMessage", "Fill All The Required Field");
        return;
      }
      let payload = {
        vehicleType: this.vehicleType,
        regNo: this.regNo,
        token: this.getToken,
      };
      this.$store.dispatch("AddAsset", payload);
      this.dialog = false;
    },
  },
  computed: {
    getToken() {
      return this.$store.getters.getToken;
    },
    getUser() {
      return this.$store.getters.getUser;
    },
  },
};
</script>
<template>
  <div>
    <v-container>
      <v-card-actions class="ma-5">
        <v-spacer></v-spacer>
        <div>
          <AddAsset />
        </div>
      </v-card-actions>
      <v-data-table
        :headers="dessertHeaders"
        :items="GetAssets"
        item-key="_id"
        show-expand
        :single-expand="true"
        hide-default-footer
        class="elevation-1"
        :loading="TableLoading"
        loading-text="Loading... Please wait"
      >
        <template v-slot:item.addBy="{ item }">
          <span>
            {{ item.addBy.personalInfo.firstName }}
            {{ item.addBy.personalInfo.lastName }}
          </span>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-card v-if="item.rider" class="mt-10" width="500" outlined>
              <v-list-item three-line>
                <v-list-item-avatar>
                  <v-img :src="item.rider.personalInfo.profileImage"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <div class="text-overline mb-4">Rider/Driver Info</div>
                  <div>
                    First Name : {{ item.rider.personalInfo.firstName }}
                    <br />
                    <v-divider />
                    <br />
                    Last Name : {{ item.rider.personalInfo.lastName }}
                    <br />
                    <v-divider />
                    <br />
                    Mobile : {{ item.rider.personalInfo.phoneNumber }}
                    <br />
                    <v-divider />
                    <br />
                    <div class="text-center">
                      <v-btn
                        @click="UnLinkAsset(item._id, item.rider._id)"
                        color="#ba1465"
                        dark
                      >
                        Unlink Rider/Driver
                      </v-btn>
                    </div>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-card>
            <v-row>
              <v-col
                :key="pastRiders.endDate"
                v-for="pastRiders in item.pastRiders"
                cols="12"
                sm="12"
                md="6"
              >
                <v-card class="mt-10" width="500" outlined>
                  <v-list-item three-line>
                    <v-list-item-avatar>
                      <v-img :src="pastRiders.profileImage"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <div class="text-overline mb-4">
                        Past Rider/Driver Info
                      </div>
                      <div>
                        First Name : {{ pastRiders.firstName }}
                        <br />
                        <v-divider />
                        <br />
                        Last Name : {{ pastRiders.lastName }}
                        <br />
                        <v-divider />
                        <br />
                        Mobile : {{ pastRiders.phoneNumber }}
                        <br />
                        <v-divider />
                        <br />
                        Start Date :
                        {{ DisplayTimeFormat(pastRiders.startDate) }}
                        <br />
                        <v-divider />
                        <br />
                        End Date : {{ DisplayTimeFormat(pastRiders.endDate) }}
                        <br />
                        <v-divider />
                        <br />
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <div class="ma-5">
                <UpdateAssetInfo :Asset="item" />
              </div>
              <v-spacer></v-spacer>
            </v-card-actions>
          </td>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination
          color="info"
          v-model="page"
          :length="TotalItems"
        ></v-pagination>
      </div>
    </v-container>
  </div>
</template>
<script>
import moment from "moment";
import AddAsset from "../components/AddAsset.vue";
import UpdateAssetInfo from "../components/UpdateAssetInfo.vue";

export default {
  components: {
    AddAsset,
    UpdateAssetInfo,
  },
  data() {
    return {
      page: 1,
      Currpage: 1,
      expanded: [],
      singleExpand: false,
      dessertHeaders: [
        {
          text: "Reg Number",
          align: "start",
          value: "regNo",
        },
        { text: "Vehicle Type", value: "vehicleType" },
        { text: "Added By", value: "addBy" },
      ],
      desserts: [],
    };
  },
  computed: {
    getToken() {
      return this.$store.getters.getToken;
    },
    GetAssets() {
      return this.$store.getters.getAssets;
    },
    TableLoading() {
      return this.$store.getters.TableLoading;
    },
    TotalItems() {
      let total = this.$store.getters.totalItems;
      return Math.ceil(total / 20);
    },
  },
  methods: {
    UnLinkAsset(AssetId, riderId) {
      let payload = {
        riderId: riderId,
        AssetId: AssetId,
        token: this.getToken,
      };
      this.$store.dispatch("UnLinkAsset", payload);
    },
    DisTime(payload) {
      return moment(payload).calendar();
    },
    DisplayTimeFormat(payload) {
      return moment(payload).format("DD MMM YYYY, LTS");
    },
    getAssets() {
      let payload = {
        page: this.page,
        token: this.getToken,
      };
      this.$store.dispatch("getAsset", payload);
    },
  },
  created() {
    this.getAssets();
  },
  watch: {
    page: function (val) {
      if (this.page != this.Currpage) {
        this.Currpage = val;
        let payload = {
          page: this.page,
          token: this.getToken,
        };
        this.$store.dispatch("GetAssets", payload);
        return;
      }
    },
  },
};
</script>

<style scoped>
.PendingVerify {
  background-color: #d6a906;
  padding: 10px;
  color: #fff;
}
.Verify {
  background-color: #0aad6c;
  padding: 10px;
  color: #fff;
}
</style>
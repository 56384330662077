<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" class="ma-3" color="#ba1465" dark
          >Update Asset info
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dark color="#ba1465">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Asset Info</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="dialog = false"> Close </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <div class="mb-5">
                <b>Link Rider/Diver</b>
              </div>
              <v-select
                :items="GetRiders"
                filled
                label="Select Rider"
                dense
                item-value="_id"
                item-text="personalInfo.firstName"
                v-model="riderId"
              ></v-select>
              <div class="text-center mt-5">
                <v-btn @click="LinkRider()" color="#ba1465" dark>
                  Link Rider/Driver
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-sheet
                @click="pickImageDoc"
                class="pa-2 mx-auto"
                height="120"
                width="270"
                color="grey lighten-3"
              >
                <v-sheet
                  elevation="2"
                  class="mx-auto pa-3 text-center"
                  height="100"
                  width="250"
                >
                  <v-icon x-large color="#ba1465"> mdi-camera-plus </v-icon>
                  <input
                    required
                    type="file"
                    style="display: none"
                    @change="loadImgDoc"
                    ref="imageInputDoc"
                  />
                  <br />
                  <v-btn color="#ba1465" text>Upload Asset Document</v-btn>
                </v-sheet>
              </v-sheet>
              <div class="text-center" v-if="imgUrlDoc">
                <v-img
                  @click="pickImageDoc"
                  height="300"
                  width="100%"
                  :src="imgDoc"
                  contain
                  class="imgDesign"
                ></v-img>
                <v-row>
                  <v-col cols="12" sm="3"></v-col>
                  <v-col class="mt-5" cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="title"
                      label="Document Name"
                      filled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3"></v-col>
                </v-row>
                <v-btn @click="UploadAssetDoc()" dark color="#ba1465" class="">
                  Upload Document
                </v-btn>
              </div>
              <div class="mt-5" v-for="i in Asset.assetDoc" :key="i.url">
                <v-img height="300" width="100%" :src="i.url" contain></v-img>
                <v-row>
                  <v-col cols="12" sm="3"></v-col>
                  <v-col class="mt-5" cols="12" sm="6" md="6">
                    <v-text-field
                      disabled
                      v-model="i.title"
                      label="Document Name"
                      filled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3"></v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["Asset"],
  data() {
    return {
      riderId: "",
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      imgUrlDoc: "",
      imgDoc: "",
      title: "",
    };
  },
  methods: {
    pickImageDoc() {
      this.$refs.imageInputDoc.click();
    },
    loadImgDoc(e) {
      this.imgUrlDoc = e.target.files[0];
      var fileReader = new FileReader();
      fileReader.readAsDataURL(e.target.files[0]);
      fileReader.onload = (e) => {
        this.imgDoc = e.target.result;
      };
    },
    LinkRider() {
      if (this.riderId == "") {
        this.$store.commit("setSnackbar", true);
        this.$store.commit("setServerMessage", "Fill All The Required Field");
        return;
      }
      let payload = {
        riderId: this.riderId,
        AssetId: this.Asset._id,
        token: this.getToken,
      };
      this.$store.dispatch("LinkRiderToAsset", payload);
      this.dialog = false;
    },
    UploadAssetDoc() {
      if (this.title == "") {
        this.$store.commit("setSnackbar", true);
        this.$store.commit("setServerMessage", "Fill All The Required Field");
        return;
      }
      let payload = {
        title: this.title,
        image: this.imgUrlDoc,
        token: this.getToken,
        assetId: this.Asset._id,
      };
      this.$store.dispatch("UploadAssetDoc", payload);
      this.dialog = false;
      this.imgUrlDoc = "";
      this.imgDoc = "";
    },
  },
  computed: {
    getToken() {
      return this.$store.getters.getToken;
    },
    GetRiders() {
      return this.$store.getters.getRiders;
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        let payload = {
          page: 1,
          token: this.getToken,
        };
        this.$store.dispatch("getAllRider", payload);
      }
    },
  },
};
</script>
<style scoped>
.imgDesign {
  cursor: pointer;
}
</style>